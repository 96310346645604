<template>
  <div class="accountMgrList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="排班设置" permission="schedule" @click="scheduleClick"></v-button>
        <v-button text="导入楼宇管理员" permission="importManager" @click="importBuildingClick"></v-button>
        <v-button text="导入门岗" permission="importGatekeeper" @click="importDoorClick"></v-button>
        <v-button text="创建管理账号" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="登录名" v-model="searchParams.logingName"></v-input>
        <v-input label="手机号" v-model="searchParams.phone"></v-input>
        <v-select label="角色类型" v-model="searchParams.roleType" :options="roleTypeOps"></v-select>
        <v-select2 label="角色归属" v-model="searchParams.organizeId" :subjoin="roleExtraParams" v-bind="organizeParams"></v-select2>
        <v-select2 label="角色名称" v-model="searchParams.roleId" :subjoin="roleExtraParams" v-bind="roleParams"></v-select2>
        <v-select2 label="所属组织" v-model="searchParams.orgId" v-bind="orgParams"></v-select2>
        <v-select label="登录平台" v-model="searchParams.appType" :options="appTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.startIntime" :endTime.sync="searchParams.endIntime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="update || edit" text="编辑" type="text" @click="toEdit(scope.row)"></v-button>
        <v-button v-if="!scope.row.isCurrentUser && scope.row.status" permission="on_off" text="关闭" type="text" @click="aloneUpload(scope.row, 0)"></v-button>
        <v-button v-if="!scope.row.isCurrentUser && !scope.row.status" permission="on_off" text="开启" type="text" @click="aloneUpload(scope.row, 1)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量开启" permission="on_off" :disabled="scope.selectedData.ids.length === 0" @click="batch(scope.selectedData, 1)"></v-button>
        <v-button text="批量关闭" permission="on_off" :disabled="scope.selectedData.ids.length === 0" @click="batch(scope.selectedData, 0)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getOrganizeURL,
  getRolesURL,
  getRoleTypeURL
} from './api'
import {
  statusOps,
  statusMap,
  appTypeOps
} from './map'
import { generateMapByOpts } from 'common/utils'
export default {
  name: 'AccountMgrList',
  data () {
    let _this = this
    return {
      statusOps,
      appTypeOps,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      roleTypeOps: [],
      roleTypeMap: {},
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      searchParams: {
        userName: '',
        logingName: '',
        phone: '',
        roleType: undefined,
        organizeId: '',
        roleId: '',
        orgId: '',
        appType: undefined,
        status: undefined,
        startIntime: '',
        endIntime: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'loginName',
          label: '登录名'
        },
        {
          prop: 'phone',
          label: '手机号'
        },
        {
          prop: 'appType',
          label: '登录平台'
        },
        {
          prop: 'roleName',
          label: '角色名称'
        },
        {
          prop: 'userTypeText',
          label: '角色类型',
          formatter (row) {
            return _this.roleTypeMap[row.userType]
          }
        },
        {
          prop: 'orgNameNew',
          label: '角色归属',
          formatter (row) {
            return row.orgName ? row.orgName : '智慧园区一体化平台'
          }
        },
        {
          prop: 'orgNames',
          label: '所属组织'
        },
        {
          prop: 'loginCnt',
          label: '登录次数',
          align: 'right'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'createTime',
          label: '创建时间'
        },
        {
          prop: 'lastLoginTime',
          label: '最新登录时间'
        }
      ]
    }
  },
  computed: {
    roleExtraParams () {
      return {
        roleType: this.searchParams.roleType,
        organizeId: this.searchParams.organizeId
      }
    },
    update () {
      return this.$store.getters.getPermission('update')
    },
    edit () {
      return this.$store.getters.getPermission('edit')
    }
  },
  mounted () {
    const orgTree = sessionStorage.getItem('orgTree')
    if (orgTree) {
      sessionStorage.removeItem('orgTree')
    }
    this.getRoleType()
  },
  methods: {
    getRoleType () {
      this.$axios.get(getRoleTypeURL).then(res => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.roleTypeMap = generateMapByOpts(this.roleTypeOps)
          !this.searchParams.roleType && (this.searchParams.roleType = data[0].id)
        }
      })
    },
    updateStatus (ids, status, dataObjects) {

      // 操作日志添加操作对象 规则：角色名称-归属层级
      let dataObject = dataObjects.join(';')

      let _this = this
      let postData = {
        userIds: ids.split(','),
        status,
        dataObject: dataObject
      }
      _this.$axios({
        method: 'put',
        url: batchURL,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async aloneUpload (row, status) {
      let msg = status ? '是否开启账号？' : '是否关闭账号？'
      let isOk = await this.$confirm(msg)
      isOk && this.updateStatus(row.id, status, [`${row.userName}-${row.loginName}`])
    },
    async batch (selected, status) {
      let isOk = await this.$confirm('是否执行该操作')
      let _ids = selected.ids.join(',')

      let dataObjects = []
      selected.data.forEach(item => {
        dataObjects.push(`${item.userName}-${item.loginName}`)
      })

      isOk && this.updateStatus(_ids, status, dataObjects)
    },
    create () {
      this.$router.push({
        name: 'accountMgrForm'
      })
    },
    toEdit (row) {
      this.$router.push({
        name: 'accountMgrForm',
        query: {
          id: row.id
        }
      })
    },
    selectable (row, index) {
      return !row.isCurrentUser
    },
    importBuildingClick () {
      this.$router.push({
        name: 'importBuildingMgter'
      })
    },
    scheduleClick(){
      this.$router.push({
        name: 'scheduleMgter'
      })
    },
    importDoorClick () {
      this.$router.push({
        name: 'importDoorer'
      })
    },
  }
}
</script>
